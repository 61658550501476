// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
// Variables
@import "variables";
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

/* raleway-300 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: local('Raleway Light'), local('Raleway-Light'),
  url('fonts/vendor/raleway-v12-latin/raleway-v12-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('fonts/vendor/raleway-v12-latin/raleway-v12-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: local('Raleway'), local('Raleway-Regular'),
  url('fonts/vendor/raleway-v12-latin/raleway-v12-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('fonts/vendor/raleway-v12-latin/raleway-v12-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* raleway-600 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: local('Raleway SemiBold'), local('Raleway-SemiBold'),
  url('fonts/vendor/raleway-v12-latin/raleway-v12-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('fonts/vendor/raleway-v12-latin/raleway-v12-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(fonts/vendor/iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url(fonts/vendor/iconfont/MaterialIcons-Regular.woff2) format('woff2'),
  url(fonts/vendor/iconfont/MaterialIcons-Regular.woff) format('woff'),
  url(fonts/vendor/iconfont/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}



body {
  padding-top: 60px;
}

#head {
  margin-bottom: 20px;
}

#topbar {
  background: #f0f0f0;
  font-size: 1rem;
  position: sticky;
  top: 0;
  margin-bottom: 20px;
  padding: 10px;
}

div.menuspacer {
  height: 20px;
}

.navbar-default {
  border-color: transparent;
}

.navbar {
  margin-bottom: 5px;
}

#claim {
  h1 {
    font-size: 48px;
  }
}

.btn, .btn-sm, .btn-group-sm > .btn {
  border-radius: 0;
}

.menu-btn {
  border-color: $brand-info;
  width: 100%;
  text-align: left;
  margin-bottom: 3px;
}

#mainmenu {
  margin-top: 0px;
  margin-bottom: 20px;
  a {
    font-size: 0.9em;
    font-weight: bold;
  }
  .btn {
    white-space: normal;
  }
}

#signet {
  text-align: center;
}

@mixin datebox {
  padding: 4px;
  text-align: center;
  line-height: 1.1;
  font-weight: bold;
}

div.startdate {
  border: 1px solid $light-grey;
  border-bottom: 6px solid $light-grey;
  min-height: 100px;
  width: 100px;
}

div.startdate, div.enddate {

  div.date-weekday {
    @include datebox;
    background-color: $brand-info;
    color: #000000;
  }
  div.date-daymonth {
    @include datebox;
    background-color: $brand-primary;

    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
  }
  div.date-year {
    @include datebox;
    background-color: $brand-primary;
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
  }
  div.date-time {
    @include datebox;
    background-color: transparent;
    color: #000000;
  }
}

div.enddate {

  margin-top: 0;
  div.date-until {
    @include datebox;
    background-color: transparent;
  }
  div.date-weekday {
    background-color: desaturate($brand-info, 50%);;
  }
  div.date-daymonth {
    background-color: desaturate($brand-primary, 50%);
  }
  div.date-year {
    background-color: desaturate($brand-primary, 50%);
  }
  div.date-time {
    background-color: transparent;
  }
}

div.startdate div.simple {
  background-color: $light-grey;
  color: $dark-grey;
}

@media(max-width: 767px) {
  #topbar {
    margin-bottom: 8px;
  }
  h1 {
    font-size: 28px;
  }
  #claim {
    h1 {
      font-size: 20px;
      margin: 5px 0;
    }
    h4 {
      margin: 2px 0 6px;
      font-size: 14px;
    }
  }
  div.menuspacer {
    height: 8px;
  }

}

@media(min-width: 768px) {
}

@media(min-width: 992px) {
  #maincontent {
    border-left: 2px solid $brand-info;
    min-height: 200px;
    margin-bottom: 50px;
  }
}

@media(min-width: 1200px) {
}

button.deleteimg {
  position: relative;
}

#login-hint {
  padding: 5px 14px;
}

footer {

  background-color: #F8F4F1;
  padding: 20px 0 50px;
  p {
    font-size: 12px;
    margin-bottom: 5px;
    a {
      color: #666666;
      text-decoration: none;
    }
  }
}

/*   */
div.eventcontent {
  * {
    font-size: 14px;
  }
  a.btn {
    margin: 10px 0 0 10px;
  }
}

div.eventimg {
  margin-bottom: 20px;
}

div.manager {
  background: lightgray;
  border: 1px solid gray;
  padding: 10px;
  margin: 10px 0px;
}

input.publishbutton {
  margin-top: 25px;
}

$red: #882530;
$lightred: lighten($red, 10%);
/* Datepicker */
.dtp > .dtp-content > .dtp-date-view > header.dtp-header {
  background: $red;
}

.dtp table.dtp-picker-days tr > td > a.selected {
  background: $red;
}

.dtp div.dtp-date, .dtp div.dtp-time {
  background: $lightred !important;
}

.dtp .p10 > a {
  color: #ffffff;
}

div.manageimages {
  div.btn {
    width: 120px !important;
  }
  button.deleteimg {
    background-color: $red;
    color: #fff;
    width: 120px;
    text-align: center;
    margin-bottom: 10px;
  }
  .button:hover {
    background: #333;
    cursor: pointer;
    width: 122px;
    text-align: center;
  }
  input[name = 'lead'], input[name = 'image0'], input[name = 'image1'] {
    display: none;
  }
}

.bildbox {
  padding: 5px 0 20px;
  margin-bottom: 20px;
  border: 2px dotted #CB6E3E;
  border-left: none;
}

.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

.material-icons.md-72 {
  font-size: 72px;
}

#previewLead {
  width: 100px;
  height: 100px;
}

#previewimage0, #previewimage1 {
  width: 400px;
}

#repetitiondateswitch {
  display: none;
}

#repetitiondate {
  display: none;
  margin-bottom: 30px;
}

#repetitiondate.showrepetitions {
  display: block;
}

#dateamount, #finaldate {
  max-width: 100px;
  text-align: right;
}

div.selecteddate {
  border: 2px solid #D26622;
  margin: 6px 6px 6px;
  padding: 0 0 0 10px;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;

  button {
    margin-left: 10px;
    border: 0;
    background-color: #D26622;
    color: #fff
  }
  button:hover {
    color: $red;
  }
}

#singledates {
  padding-top: 20px;
}

div.leadimg img {
  margin-bottom: 20px;
}

div.profile {
  border: 1px solid #ddd;
  color: #000;
  font-size: 18px;

  div.row {
    border-bottom: 1px dashed #ddd;
    div {
      padding: 10px;
    }
  }
  .profilelabel {
    background: #ddd;
    padding: 5px 10px;
    color: #000;
    font-weight: bold;
  }
}

span.badge{

  background:#ccc;
  color:#000;
  margin-top:5px;
  a{
    display:block;
    color:#000;
    text-shadow: 1px 1px 1px #fff;
    padding:3px;
    text-decoration:none;
  }
  a:hover{
    color:#fff;
    text-shadow: 1px 1px 1px #000;
  }

}


/* ---- cookiehinweis ---- */
#cookiedingsbums a {
  color: #fff;
  font-weight: normal;
  text-decoration: underline;
}

#cookiedingsbums a:hover {
  text-decoration: underline;
}

#cookiedingsbums div {
  padding-top: 5px;
  padding-right: 40px;
}

#cookiedingsbums {
  color:#eee;
  text-align: center;
  border-top: 1px solid #fff;
  background: #000;

  position: fixed;
  bottom: 0px;
  z-index: 10000;
  width: 100%;

  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
}

#cookiedingsbums .btn{
  background:#fff;
  color:#000;
}

#cookiedingsbumsCloser {
  color: #888;
  font: 12px/100% arial, sans-serif;
  position: absolute;
  right: 5px;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  top: 5px;
  cursor: pointer;
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-bottom: 1px solid #7b92a9;
  border-right: 1px solid #7b92a9;
  padding: 4px;
  background: #ced6df; /* Old browsers */

}

#cookiedingsbumsCloser:hover {
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  border-top: 1px solid #7b92a9;
  border-left: 1px solid #7b92a9;
}

#cookiedingsbums p{
  margin-bottom:5px;
}

#newsingledate{
  width:50px !important;
  height:40px !important;
  color:transparent;
}
